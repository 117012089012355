<template>
    <div class="JFI Wrapper-section" id="JFIPage">
        <div class="Trigger TriggerTop" id="JFITriggerTop"></div>
        <div class="JFIPanel">
            <header class="JFIPanel-content">
                <h2 class="title JFIPanel-title">We’re carving out a new way of engaging with B2B customers.</h2>
                <h2 class="JFIPanel-words title JFIPanel-title" id="JFIPanel-words">
                    <span :class="[word === 'Next agency' ? 'colored':'']" :id="`JFIWord_${ index }`" :key="index" class="JFIPanel-word" v-for="(word, index) in words">{{word}}</span>
                </h2>
            </header>
            <div class="JFIPanel-history">
                <h3 class="subtitle JFI-subtitle">No matter your stage, your need or your challenge, we’ll help you do the heavy lifting.</h3>
                <div class="JFIPanel-text">
                    <div class="column">
                        <p>Founded by Nick Fox, JFI was born out of frustration with traditional sales practice where organisations were more obsessed with process than customers. Today we’re a team of creatives, disruptors, troublemakers and dreamers, spending every day trying to save buyers from bad sales practices.</p>
                    </div>
                    <div class="column">
                        <p>We’re not trying to be a SaaS business - we’re building for what comes next. We are services heavy. Because that’s what our customers need. We’re not an agency. We started off calling ourselves an “anti-agency” but that anchored the story in the past.</p>
                    </div>
                    <div class="column">
                        <p>We think of ourselves as a “next agency”. Software-centric, augmented by strategic and creative services delivered into a niche where we are experts. Not just advice and ideas. On the tools, with you in the trenches. Building your ability to do it yourself.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="Trigger TriggerBottom" id="JFITriggerBottom"></div>
    </div>
</template>

<script>
    export default {
        name: 'JFI',
        data() {
            return {
                publicPath: process.env.BASE_URL,
                sceneTimer: null,
                scrollMagicTrigger: ['JFITriggerTop'],
                scrollMagicSectionId: ['JFIPage'],
                timelineWords: null,
                words: [
                    'Marketing agency',
                    'Design agency',
                    'Sales agency',
                    'Software agency',
                    'Digital agency',
                    'Next agency'
                ]
            };
        },
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 1000);
            },
            createScene() {
                clearInterval(this.sceneTimer);
                const SMC = new this.$scrollmagic.Controller();

                for (let i = 0; i < this.scrollMagicSectionId.length; i++) {
                    const scene = new this.$scrollmagic.Scene({
                        triggerElement: `#${this.scrollMagicTrigger[i]}`,
                        triggerHook: 'onEnter'
                    }).setClassToggle(`#${this.scrollMagicSectionId[i]}`, 'visible'); // add class to reveal
                    SMC.addScene(scene);
                }

                const texts = document.querySelectorAll('#JFIPanel-words .JFIPanel-word');
                let prev = null;
                const animate = (curr, currIndex) => {
                    let index = (currIndex + 1) % texts.length;
                    setTimeout(() => {
                        if (prev) {
                            prev.classList.remove('show');
                        }
                        curr.classList.add('show');
                        prev = curr;
                        animate(texts[index], index);
                    }, 1200);
                };
                animate(texts[0], 0);
            }
        }
    };
</script>
